import React from 'react';
import './BillingsDetail.css'
const BillingsDetail = () => {
    return (
        <div>
            Счет детальная
        </div>
    );
};

export default BillingsDetail;