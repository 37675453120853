import {forwardRef, useEffect} from "react";
import useDebouncingFunction from "../../../../hooks/debouncingfunction/useDebouncingFunction";
import {generateGuid} from "../../../../features/guidgenerator/GuidGenerator";
import {FormControl, Input, InputLabel} from "@mui/material";
import {IMaskInput} from "react-imask";
import {replaceStringToNumber} from "../../../../features/entityhelper/EntityHelper";
import useControlledValue from "../../../../hooks/controlled/useControlledValue";

const PhoneInput = ({
                        defaultValue = '',
                        onChange,
                        label = '',
                        variant = 'standard',
                        useDebounce = false,
                        returnNumber = false,
                        sx = { width: 300 },
                        controlled = false}) => {

    const [value, setValue] = useControlledValue({defaultValue: defaultValue ? `${defaultValue}` : '', controlled, initialStateValue: ''})

    useEffect(() => {
        console.log(value)
    }, [value]);

    const updateValue = (newValue) => {
        if(returnNumber)
            onChange?.(replaceStringToNumber(newValue) || 0)
        else
            onChange?.(newValue)
    }

    const debounceUpdateValue = useDebouncingFunction(updateValue, 1200)
    const handleChange = (e) => {
        let newValue = e ? e.target.value : '';
        if(useDebounce)
            debounceUpdateValue(newValue)
        else
            updateValue(newValue)

        setValue(newValue);
    };

    const guid = generateGuid()
    return (
        <FormControl variant="standard">
            <InputLabel htmlFor={guid}>{label}</InputLabel>
            <Input
                size={"small"}
                value={value}
                sx={sx}
                id={guid}
                onChange={handleChange}
                inputComponent={TextMaskCustom}
                variant={variant}
            />
        </FormControl>

    );
};

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+7 (###) ###-##-##"
            definitions={{
                '#': /[0-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}

        />
    );
});

export default PhoneInput;