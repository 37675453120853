import useLoader from "./useLoader";
import useMessageHub from "./useMessageHub";

const UseSignalRFunk = ({SignalRContext, connectionRef, argsExternal = [], checkConnection}) => {
    const {show, hide} = useLoader()
    const addMessage = useMessageHub()
    const signalRFunk = (method = '', argFunk = [], successCallback, useLoader = true, errorCallback, finallyCallback) => {
        if(!checkConnection?.()){
            let count = 0;
            let intervalId = setInterval(() => {
                if(checkConnection?.()){
                    signalRFunk(method, argFunk, successCallback, useLoader, errorCallback, finallyCallback)
                    clearInterval(intervalId)
                }
                else if(count === 5)
                    clearInterval(intervalId)
                else
                    count++;
            }, 1500)
        }
        else{
            if(useLoader)
                show()
            SignalRContext.invoke(method, ...argFunk, ...argsExternal)
                .then(res => {
                    if(successCallback)
                        successCallback?.(res)
                })
                .catch(res => {
                    addMessage(`Ошибка: ${res}`)
                    if(errorCallback)
                        errorCallback?.(res)
                })
                .finally(res => {
                        if(useLoader)
                            hide()
                        if(finallyCallback)
                            finallyCallback?.(finallyCallback)
                    }
                )
        }
    }
    return {
        signalRFunk
    };
};

export default UseSignalRFunk;