import React from 'react';
import './SpecificationDetail.css'
const SpecificationDetail = () => {
    return (
        <div>
            Спецификации детальная
        </div>
    );
};

export default SpecificationDetail;