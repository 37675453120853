import React from 'react';

const ChevronDownIcon = ({classNames}) => {
    return (
        <svg className={classNames} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L12 15L18 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default ChevronDownIcon;