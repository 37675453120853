import {useState} from 'react';
import GuidsHeader from "../../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../../components/guids/search/GuidsSearchInput";
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import GuidsBody from "../../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../../components/tableb/TableBootstrap";
import GuidsWrapper from "../../../../../../components/guids/wrapper/GuidsWrapper";
import EditIcon from "../../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../../components/UI/icons/BackCrossIcon";
import SelectorComponent from "../../detail/components/selectorcomponents/SelectorComponent";
import ListIcon from "../../../../../../components/UI/icons/ListIcon";
import DropdownDefault from "../../../../../../components/UI/selects/dropdowndefault/DropdownDefault";

const EquipmentComponentsList = ({components = [], removeComponent, addComponent, changeComponent, getCurrentIdsComponents, componentsList = [], unitsMeasurement = []}) => {
    const [searchValue, setSearchValue] = useState([])
    const [showModalEditRecipe, setShowModalEditRecipe] = useState(false)
    const [componentForEdit, setComponentForEdit] = useState(null)
    const [showSelectorComponent, setShowSelectorComponent] = useState(false)

    const schema = [
        {
            title: 'ID',
            callbackItem: item => item?.id,
        },
        {
            title: 'Название',
            callbackItem: item => item?.component?.name,
        },
        {
            title: 'Кол-во',
            callbackItem: item => item?.quantityComponent,
        },
        {
            title: 'Единица измерения',
            callbackItem: item => item?.unitsMeasurement?.value || 'Не выбрано',
        },
    ]

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => {
                setShowModalEditRecipe(true)
                setComponentForEdit(item)
            }
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeComponent(item?.guid)
        },
    ]

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК КОМПОНЕНТОВ'}/>
                <div className={'flex center gap'}>
                    <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>
                    <Button variant={'primary'} onClick={() => setShowSelectorComponent(true)}>Добавить компонент</Button>
                </div>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={components}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <Modal show={showModalEditRecipe} onHide={() => {
                setShowModalEditRecipe(false)
                setComponentForEdit(null)
            }}>
                <Modal.Header>
                    <Modal.Title>Редактирование компонента</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Единица измерения</Form.Label>
                            <InputGroup>
                                <InputGroup.Text><ListIcon/></InputGroup.Text>
                                <DropdownDefault
                                    currentValue={componentForEdit?.unitMeasurement}
                                    onChange={e => setComponentForEdit({...componentForEdit, unitsMeasurement: e})}
                                    options={unitsMeasurement}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label></Form.Label>
                            <InputGroup>
                                <InputGroup.Text><ListIcon/></InputGroup.Text>
                                <Form.Control
                                    type={'number'}
                                    onChange={e => setComponentForEdit({...componentForEdit, quantityComponent: Number(e.target.value)})}
                                />
                            </InputGroup>

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        setShowModalEditRecipe(false)
                        setComponentForEdit(null)
                    }}>Отменить</Button>
                    <Button variant={"primary"} onClick={() => {
                        setShowModalEditRecipe(false)
                        setComponentForEdit(null)
                        changeComponent(componentForEdit)
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSelectorComponent} fullscreen={true} onHide={() => {

            }}>
                <Modal.Header>
                    <Modal.Title>Выбор компонента</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectorComponent components={componentsList} onSelect={addComponent} currentComponentsIds={getCurrentIdsComponents()}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => {setShowSelectorComponent(false)}}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </GuidsWrapper>
    );
};

export default EquipmentComponentsList;